.react-grid-layout {
  transition: height .2s;
  position: relative;
}

.react-grid-item {
  transition: left .2s, top .2s, width .2s, height .2s;
}

.react-grid-item img {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform, width, height;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
  transition: none;
}

.react-grid-item.react-draggable-dragging {
  z-index: 3;
  will-change: transform;
  transition: none;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  opacity: .2;
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  background: red;
  transition-duration: .1s;
}

.react-grid-item.react-grid-placeholder.placeholder-resizing {
  transition: none;
}

.react-grid-item > .react-resizable-handle {
  width: 20px;
  height: 20px;
  position: absolute;
}

.react-grid-item > .react-resizable-handle:after {
  content: "";
  border-bottom: 2px solid #0006;
  border-right: 2px solid #0006;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 3px;
  right: 3px;
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
  transform: rotate(90deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
  transform: rotate(270deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w, .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  cursor: ew-resize;
  margin-top: -10px;
  top: 50%;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n, .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  cursor: ns-resize;
  margin-left: -10px;
  left: 50%;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Poppins, ui-sans-serif, system-ui;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: hsl(var(--b1) / var(--tw-bg-opacity, 1));
  color: hsl(var(--bc) / var(--tw-text-opacity, 1));
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --pf: 205 73% 57%;
  --sf: 27 87% 60%;
  --af: 154 49% 53%;
  --nf: 233 27% 6%;
  --b2: 0 0% 93%;
  --b3: 0 0% 86%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 207 27% 14%;
  --sc: 26 32% 14%;
  --ac: 151 21% 13%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --p: 205 73% 64%;
  --s: 27 87% 67%;
  --a: 154 49% 60%;
  --n: 233 27% 13%;
  --nc: 210 38% 95%;
  --b1: 0 0% 100%;
  --bc: 233 27% 13%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --btn-text-case: uppercase;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    color-scheme: dark;
    --pf: 205 61% 38%;
    --sf: 32 89% 45%;
    --af: 91 100% 26%;
    --nf: 31 81% 3%;
    --bc: 145 0% 78%;
    --sc: 29 53% 12%;
    --nc: 26 11% 80%;
    --inc: 239 85% 93%;
    --suc: 126 38% 89%;
    --wac: 29 59% 11%;
    --erc: 11 100% 91%;
    --p: 205 61% 45%;
    --pc: 180 7% 8%;
    --s: 32 89% 52%;
    --a: 91 100% 33%;
    --ac: 0 0% 0%;
    --n: 31 81% 10%;
    --b1: 0 0% 0%;
    --in: 221 83% 53%;
    --su: 142 76% 36%;
    --wa: 32 95% 44%;
    --er: 0 72% 51%;
    --b2: 0 0% 5%;
    --b3: 0 2% 10%;
    --rounded-box: 1rem;
    --rounded-btn: .5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: .25s;
    --animation-input: .2s;
    --btn-text-case: uppercase;
    --btn-focus-scale: .95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: .5rem;
  }
}

[data-theme="light"] {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --pf: 205 73% 57%;
  --sf: 27 87% 60%;
  --af: 154 49% 53%;
  --nf: 233 27% 6%;
  --b2: 0 0% 93%;
  --b3: 0 0% 86%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 207 27% 14%;
  --sc: 26 32% 14%;
  --ac: 151 21% 13%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --p: 205 73% 64%;
  --s: 27 87% 67%;
  --a: 154 49% 60%;
  --n: 233 27% 13%;
  --nc: 210 38% 95%;
  --b1: 0 0% 100%;
  --bc: 233 27% 13%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --btn-text-case: uppercase;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
}

[data-theme="dark"] {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --pf: 205 61% 38%;
  --sf: 32 89% 45%;
  --af: 91 100% 26%;
  --nf: 31 81% 3%;
  --bc: 145 0% 78%;
  --sc: 29 53% 12%;
  --nc: 26 11% 80%;
  --inc: 239 85% 93%;
  --suc: 126 38% 89%;
  --wac: 29 59% 11%;
  --erc: 11 100% 91%;
  --p: 205 61% 45%;
  --pc: 180 7% 8%;
  --s: 32 89% 52%;
  --a: 91 100% 33%;
  --ac: 0 0% 0%;
  --n: 31 81% 10%;
  --b1: 0 0% 0%;
  --in: 221 83% 53%;
  --su: 142 76% 36%;
  --wa: 32 95% 44%;
  --er: 0 72% 51%;
  --b2: 0 0% 5%;
  --b3: 0 2% 10%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
}

* {
  scrollbar-color: initial;
  scrollbar-width: initial;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 475px) {
  .container {
    max-width: 475px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.alert {
  text-align: center;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: var(--rounded-box, 1rem);
  --alert-bg: hsl(var(--b2));
  --alert-bg-mix: hsl(var(--b1));
  background-color: var(--alert-bg);
  grid-auto-flow: row;
  align-content: flex-start;
  place-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  display: grid;
}

@media (width >= 640px) {
  .alert {
    text-align: left;
    grid-template-columns: auto minmax(auto, 1fr);
    grid-auto-flow: column;
    justify-items: start;
  }
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.badge {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: var(--rounded-badge, 1.9rem);
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 1.25rem;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

@media (hover: hover) {
  .label a:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
  }

  .menu li > :not(ul):not(.menu-title):not(details):active, .menu li > :not(ul):not(.menu-title):not(details).active, .menu li > details > summary:active {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--nc) / var(--tw-text-opacity));
  }

  .tab:hover {
    --tw-text-opacity: 1;
  }

  .table tr.hover:hover, .table tr.hover:nth-child(2n):hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-color: #0000;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  text-transform: var(--btn-text-case, uppercase);
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  outline-color: hsl(var(--bc) / 1);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn:disabled {
  pointer-events: none;
}

.btn-square {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-group > input[type="radio"].btn {
  appearance: none;
}

.btn-group > input[type="radio"].btn:before {
  content: attr(data-title);
}

.btninput[type="checkbox"], .btninput[type="radio"] {
  appearance: none;
  width: auto;
}

.btninput[type="checkbox"]:after, .btninput[type="radio"]:after {
  --tw-content: attr(aria-label);
  content: var(--tw-content);
}

.card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
}

.card:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.card-actions {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.card figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.image-full {
  display: grid;
}

.card.image-full:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  opacity: .75;
  border-radius: var(--rounded-box, 1rem);
  position: relative;
}

.card.image-full:before, .card.image-full > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.card.image-full > figure img {
  object-fit: cover;
  height: 100%;
}

.card.image-full > .card-body {
  z-index: 20;
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  position: relative;
}

.checkbox {
  --chkbg: var(--bc);
  --chkfg: var(--b1);
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.collapse:not(td):not(tr):not(colgroup) {
  visibility: visible;
}

.collapse {
  border-radius: var(--rounded-box, 1rem);
  grid-template-rows: auto 0fr;
  width: 100%;
  transition: grid-template-rows .2s;
  display: grid;
  position: relative;
  overflow: hidden;
}

.collapse-title, .collapse > input[type="checkbox"], .collapse > input[type="radio"], .collapse-content {
  grid-row-start: 1;
  grid-column-start: 1;
}

.collapse > input[type="checkbox"], .collapse > input[type="radio"] {
  appearance: none;
  opacity: 0;
}

.collapse-content {
  visibility: hidden;
  cursor: unset;
  grid-row-start: 2;
  grid-column-start: 1;
  min-height: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open], .collapse-open, .collapse:focus:not(.collapse-close), .collapse:not(.collapse-close):has( > input[type="checkbox"]:checked), .collapse:not(.collapse-close):has( > input[type="radio"]:checked) {
  grid-template-rows: auto 1fr;
}

.collapse[open] > .collapse-content, .collapse-open > .collapse-content, .collapse:focus:not(.collapse-close) > .collapse-content, .collapse:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-content, .collapse:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-content {
  visibility: visible;
  min-height: fit-content;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown > :not(summary):focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.dropdown .dropdown-content {
  position: absolute;
}

.dropdown:not(details) .dropdown-content {
  visibility: hidden;
  opacity: 0;
  transform-origin: top;
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.dropdown-end .dropdown-content {
  right: 0;
}

.dropdown-left .dropdown-content {
  transform-origin: 100%;
  top: 0;
  bottom: auto;
  right: 100%;
}

.dropdown-right .dropdown-content {
  transform-origin: 0;
  top: 0;
  bottom: auto;
  left: 100%;
}

.dropdown-bottom .dropdown-content {
  transform-origin: top;
  top: 100%;
  bottom: auto;
}

.dropdown-top .dropdown-content {
  transform-origin: bottom;
  top: auto;
  bottom: 100%;
}

.dropdown-end.dropdown-right .dropdown-content, .dropdown-end.dropdown-left .dropdown-content {
  top: auto;
  bottom: 0;
}

.dropdown.dropdown-open .dropdown-content, .dropdown:not(.dropdown-hover):focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  visibility: visible;
  opacity: 1;
}

@media (hover: hover) {
  .dropdown.dropdown-hover:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
  }

  .btm-nav > .disabled:hover, .btm-nav > [disabled]:hover {
    pointer-events: none;
    --tw-border-opacity: 0;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-bg-opacity: .1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    --tw-text-opacity: .2;
  }

  .btn:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--b3) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  }

  .btn-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  }

  .btn-secondary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--sf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--sf) / var(--tw-bg-opacity));
  }

  .btn-neutral:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--nf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--nf) / var(--tw-bg-opacity));
  }

  .btn-warning:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--wa) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  }

  .btn-error:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--er) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--er) / var(--tw-bg-opacity));
  }

  .btn.glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .btn-ghost:hover {
    --tw-border-opacity: 0;
    background-color: hsl(var(--bc) / var(--tw-bg-opacity));
    --tw-bg-opacity: .2;
  }

  .btn-outline:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--bc) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--bc) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--b1) / var(--tw-text-opacity));
  }

  .btn-outline.btn-primary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--pc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-secondary:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--sf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--sf) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--sc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-accent:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--af) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--af) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--ac) / var(--tw-text-opacity));
  }

  .btn-outline.btn-success:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--su) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--su) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--suc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-info:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--in) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--in) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--inc) / var(--tw-text-opacity));
  }

  .btn-outline.btn-warning:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--wa) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--wa) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--wac) / var(--tw-text-opacity));
  }

  .btn-outline.btn-error:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--er) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--er) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--erc) / var(--tw-text-opacity));
  }

  .btn-disabled:hover, .btn[disabled]:hover, .btn:disabled:hover {
    --tw-border-opacity: 0;
    background-color: hsl(var(--n) / var(--tw-bg-opacity));
    --tw-bg-opacity: .2;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    --tw-text-opacity: .2;
  }

  .btninput[type="checkbox"]:checked:hover, .btninput[type="radio"]:checked:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--pf) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  }

  .dropdown.dropdown-hover:hover .dropdown-content {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  :where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):not(.active):hover, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(.active):hover {
    cursor: pointer;
    background-color: hsl(var(--bc) / .1);
    --tw-text-opacity: 1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .tab[disabled], .tab[disabled]:hover {
    cursor: not-allowed;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    --tw-text-opacity: .2;
  }
}

.dropdown:is(details) summary::-webkit-details-marker {
  display: none;
}

.file-input {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  height: 3rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow: hidden;
}

.file-input::file-selector-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  --tw-border-opacity: 1;
  border-style: solid;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.form-control {
  flex-direction: column;
  display: flex;
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.input {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input-group {
  align-items: stretch;
  width: 100%;
  display: flex;
}

.input-group > .input {
  isolation: isolate;
}

.input-group > *, .input-group > .input, .input-group > .textarea, .input-group > .select {
  border-radius: 0;
}

.input-group :where(span) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.input-group > :first-child {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
}

.input-group > :last-child {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.join {
  border-radius: var(--rounded-btn, .5rem);
  align-items: stretch;
  display: inline-flex;
}

.join :where(.join-item), .join .join-item:not(:first-child):not(:last-child), .join :not(:first-child):not(:last-child) .join-item {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.join .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .join-item {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.join .dropdown .join-item:first-child:not(:last-child), .join :first-child:not(:last-child) .dropdown .join-item {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

.join :where(.join-item:first-child:not(:last-child)), .join :where(:first-child:not(:last-child) .join-item) {
  border-start-start-radius: inherit;
  border-end-start-radius: inherit;
}

.join .join-item:last-child:not(:first-child), .join :last-child:not(:first-child) .join-item {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.join :where(.join-item:last-child:not(:first-child)), .join :where(:last-child:not(:first-child) .join-item) {
  border-start-end-radius: inherit;
  border-end-end-radius: inherit;
}

@supports not selector(:has(*)) {
  :where(.join *) {
    border-radius: inherit;
  }
}

@supports selector(:has(*)) {
  :where(.join :has(.join-item)) {
    border-radius: inherit;
  }
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.menu {
  flex-flow: column wrap;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.menu :where(li ul) {
  white-space: nowrap;
  margin-left: 1rem;
  padding-left: .5rem;
  position: relative;
}

.menu :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  -webkit-user-select: none;
  user-select: none;
  grid-auto-columns: minmax(auto, max-content) auto max-content;
  grid-auto-flow: column;
  align-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: grid;
}

.menu li.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  color: hsl(var(--bc) / .3);
}

.menu :where(li > .menu-dropdown:not(.menu-dropdown-show)) {
  display: none;
}

:where(.menu li) {
  flex-flow: column wrap;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  position: relative;
}

:where(.menu li) .badge {
  justify-self: end;
}

.mockup-code {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  border-radius: var(--rounded-box, 1rem);
  min-width: 18rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: relative;
  overflow: auto hidden;
}

.mockup-code pre[data-prefix]:before {
  content: attr(data-prefix);
  text-align: right;
  opacity: .5;
  width: 2rem;
  display: inline-block;
}

.modal {
  pointer-events: none;
  opacity: 0;
  overscroll-behavior: contain;
  z-index: 999;
  color: inherit;
  background-color: #0000;
  justify-items: center;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  margin: 0;
  padding: 0;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: grid;
  position: fixed;
  inset: 0;
  overflow-y: hidden;
}

:where(.modal) {
  align-items: center;
}

.modal-box {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  overscroll-behavior: contain;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 91.6667%;
  max-width: 32rem;
  max-height: calc(100vh - 5em);
  padding: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px #00000040;
}

.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open] {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.modal-action {
  justify-content: flex-end;
  margin-top: 1.5rem;
  display: flex;
}

.modal-toggle {
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: fixed;
}

:root:has(:is(.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open])) {
  overflow: hidden;
}

.navbar {
  padding: var(--navbar-padding, .5rem);
  align-items: center;
  width: 100%;
  min-height: 4rem;
  display: flex;
}

:where(.navbar > *) {
  align-items: center;
  display: inline-flex;
}

.progress {
  appearance: none;
  background-color: hsl(var(--bc) / .2);
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
  position: relative;
  overflow: hidden;
}

.range {
  cursor: pointer;
  appearance: none;
  --range-shdw: var(--bc);
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  overflow: hidden;
}

.range:focus {
  outline: none;
}

.select {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  background-image: linear-gradient(45deg, #0000 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, #0000 50%);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16.1px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
}

.select[multiple] {
  height: auto;
}

.stack {
  place-items: flex-end center;
  display: inline-grid;
}

.stack > * {
  z-index: 1;
  opacity: .6;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  transform: translateY(10%)scale(.9);
}

.stack > :nth-child(2) {
  z-index: 2;
  opacity: .8;
  transform: translateY(5%)scale(.95);
}

.stack > :first-child {
  z-index: 3;
  opacity: 1;
  transform: translateY(0)scale(1);
}

.steps .step {
  text-align: center;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  place-items: center;
  min-width: 4rem;
  display: grid;
}

.swap {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  place-content: center;
  display: inline-grid;
  position: relative;
}

.swap > * {
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.swap input {
  appearance: none;
}

.swap .swap-on, .swap .swap-indeterminate, .swap input:indeterminate ~ .swap-on, .swap input:checked ~ .swap-off, .swap-active .swap-off, .swap input:indeterminate ~ .swap-off {
  opacity: 0;
}

.swap input:checked ~ .swap-on, .swap-active .swap-on, .swap input:indeterminate ~ .swap-indeterminate {
  opacity: 1;
}

.tabs {
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
}

.tab {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  --tab-padding: 1rem;
  --tw-text-opacity: .5;
  --tab-color: hsl(var(--bc) / var(--tw-text-opacity, 1));
  --tab-bg: hsl(var(--b1) / var(--tw-bg-opacity, 1));
  --tab-border-color: hsl(var(--b3) / var(--tw-bg-opacity, 1));
  color: var(--tab-color);
  padding-left: var(--tab-padding, 1rem);
  padding-right: var(--tab-padding, 1rem);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
  position: relative;
}

.table {
  text-align: left;
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  position: relative;
}

.table :where(.table-pin-rows thead tr) {
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  position: sticky;
  top: 0;
}

.table :where(.table-pin-rows tfoot tr) {
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  position: sticky;
  bottom: 0;
}

.table :where(.table-pin-cols tr th) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  position: sticky;
  left: 0;
  right: 0;
}

.textarea {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  min-height: 3rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 2;
}

.toast {
  white-space: nowrap;
  flex-direction: column;
  gap: .5rem;
  min-width: fit-content;
  padding: 1rem;
  display: flex;
  position: fixed;
}

.toggle {
  --tglbg: hsl(var(--b1));
  --handleoffset: 1.5rem;
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
  --togglehandleborder: 0 0;
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .5;
  border-radius: var(--rounded-badge, 1.9rem);
  transition: background, box-shadow var(--animation-input, .2s) ease-out;
  box-shadow: var(--handleoffsetcalculator) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset, var(--togglehandleborder);
  flex-shrink: 0;
  width: 3rem;
  height: 1.5rem;
}

.alert-error {
  border-color: hsl(var(--er) / .2);
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
  --alert-bg: hsl(var(--er));
  --alert-bg-mix: hsl(var(--b1));
}

.badge-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.badge-outline {
  --tw-border-opacity: .5;
  color: currentColor;
  background-color: #0000;
  border-color: currentColor;
}

.badge-outline.badge-neutral {
  --tw-text-opacity: 1;
  color: hsl(var(--n) / var(--tw-text-opacity));
}

.badge-outline.badge-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.badge-outline.badge-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.badge-outline.badge-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.badge-outline.badge-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.badge-outline.badge-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.badge-outline.badge-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.badge-outline.badge-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.btm-nav > :where(.active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-top-width: 2px;
}

.btm-nav > .disabled, .btm-nav > [disabled] {
  pointer-events: none;
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

.btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.btn-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
  outline-color: hsl(var(--p) / 1);
}

.btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf) / var(--tw-bg-opacity));
}

.btn-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
  outline-color: hsl(var(--s) / 1);
}

.btn-secondary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf) / var(--tw-bg-opacity));
}

.btn-neutral {
  --tw-border-opacity: 1;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  outline-color: hsl(var(--n) / 1);
}

.btn-neutral.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf) / var(--tw-bg-opacity));
}

.btn-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
  outline-color: hsl(var(--wa) / 1);
}

.btn-warning.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
}

.btn-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
  outline-color: hsl(var(--er) / 1);
}

.btn-error.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
}

.btn.glass {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: currentColor;
}

.btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn-ghost {
  color: currentColor;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
  outline-color: currentColor;
}

.btn-ghost.btn-active {
  --tw-border-opacity: 0;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
}

.btn-outline {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0000;
  border-color: currentColor;
}

.btn-outline.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--b1) / var(--tw-text-opacity));
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.btn-outline.btn-accent.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btn-outline.btn-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.btn-outline.btn-success.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc) / var(--tw-text-opacity));
}

.btn-outline.btn-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.btn-outline.btn-info.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc) / var(--tw-text-opacity));
}

.btn-outline.btn-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.btn-outline.btn-warning.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac) / var(--tw-text-opacity));
}

.btn-outline.btn-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.btn-outline.btn-error.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc) / var(--tw-text-opacity));
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btn-group > input[type="radio"]:checked.btn, .btn-group > .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-group > input[type="radio"]:checked.btn:focus-visible, .btn-group > .btn-active:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--p) / 1);
}

.btninput[type="checkbox"]:checked, .btninput[type="radio"]:checked {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btninput[type="checkbox"]:checked:focus-visible, .btninput[type="radio"]:checked:focus-visible {
  outline-color: hsl(var(--p) / 1);
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card :where(figure:first-child) {
  border-start-start-radius: inherit;
  border-start-end-radius: inherit;
  border-end-end-radius: unset;
  border-end-start-radius: unset;
  overflow: hidden;
}

.card :where(figure:last-child) {
  border-start-start-radius: unset;
  border-start-end-radius: unset;
  border-end-end-radius: inherit;
  border-end-start-radius: inherit;
  overflow: hidden;
}

.card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.card.bordered {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
}

.card.compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card.image-full :where(figure) {
  border-radius: inherit;
  overflow: hidden;
}

.checkbox:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / 1);
}

.checkbox:checked, .checkbox[checked="true"], .checkbox[aria-checked="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(-90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(0deg, hsl(var(--chkbg)) 43%, hsl(var(--chkfg)) 43%, hsl(var(--chkfg)) 57%, hsl(var(--chkbg)) 57%);
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  opacity: .2;
  border-color: #0000;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

[dir="rtl"] .checkbox:checked, [dir="rtl"] .checkbox[checked="true"], [dir="rtl"] .checkbox[aria-checked="true"] {
  background-image: linear-gradient(45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(-45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

details.collapse {
  width: 100%;
}

details.collapse summary {
  outline-offset: 2px;
  outline: 2px solid #0000;
  display: block;
  position: relative;
}

details.collapse summary::-webkit-details-marker {
  display: none;
}

.collapse:focus-visible, .collapse:has(.collapse-title:focus-visible), .collapse:has( > input[type="checkbox"]:focus-visible), .collapse:has( > input[type="radio"]:focus-visible) {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / 1);
}

.collapse-arrow > .collapse-title:after {
  --tw-translate-y: -100%;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  top: 50%;
  right: 1.4rem;
  box-shadow: 2px 2px;
}

[dir="rtl"] .collapse-arrow > .collapse-title:after {
  --tw-rotate: -45deg;
}

.collapse-plus > .collapse-title:after {
  content: "+";
  pointer-events: none;
  width: .5rem;
  height: .5rem;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  top: .9rem;
  right: 1.4rem;
}

.collapse:not(.collapse-open):not(.collapse-close) > input[type="checkbox"], .collapse:not(.collapse-open):not(.collapse-close) > input[type="radio"]:not(:checked), .collapse:not(.collapse-open):not(.collapse-close) > .collapse-title {
  cursor: pointer;
}

.collapse:focus:not(.collapse-open):not(.collapse-close):not(.collapse[open]) > .collapse-title {
  cursor: unset;
}

.collapse-title {
  position: relative;
}

:where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  z-index: 1;
}

.collapse-title, :where(.collapse > input[type="checkbox"]), :where(.collapse > input[type="radio"]) {
  width: 100%;
  min-height: 3.75rem;
  padding: 1rem 3rem 1rem 1rem;
  transition: background-color .2s ease-out;
}

.collapse[open] > :where(.collapse-content), .collapse-open > :where(.collapse-content), .collapse:focus:not(.collapse-close) > :where(.collapse-content), .collapse:not(.collapse-close) > :where(input[type="checkbox"]:checked ~ .collapse-content), .collapse:not(.collapse-close) > :where(input[type="radio"]:checked ~ .collapse-content) {
  padding-bottom: 1rem;
  transition: padding .2s ease-out, background-color .2s ease-out;
}

.collapse[open].collapse-arrow > .collapse-title:after, .collapse-open.collapse-arrow > .collapse-title:after, .collapse-arrow:focus:not(.collapse-close) > .collapse-title:after, .collapse-arrow:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-arrow:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
  --tw-translate-y: -50%;
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

[dir="rtl"] .collapse[open].collapse-arrow > .collapse-title:after, [dir="rtl"] .collapse-open.collapse-arrow > .collapse-title:after, [dir="rtl"] .collapse-arrow:focus:not(.collapse-close) .collapse-title:after, [dir="rtl"] .collapse-arrow:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-title:after {
  --tw-rotate: 135deg;
}

.collapse[open].collapse-plus > .collapse-title:after, .collapse-open.collapse-plus > .collapse-title:after, .collapse-plus:focus:not(.collapse-close) > .collapse-title:after, .collapse-plus:not(.collapse-close) > input[type="checkbox"]:checked ~ .collapse-title:after, .collapse-plus:not(.collapse-close) > input[type="radio"]:checked ~ .collapse-title:after {
  content: "−";
}

.dropdown.dropdown-open .dropdown-content, .dropdown:focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.file-input:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.file-input-disabled, .file-input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.file-input-disabled::placeholder, .file-input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.file-input-disabled::file-selector-button {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.file-input[disabled]::file-selector-button {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.label-text {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

.input input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input-bordered {
  --tw-border-opacity: .2;
}

.input:focus, .input:focus-within {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.join > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1px;
}

.join-item:focus {
  isolation: isolate;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.loading {
  pointer-events: none;
  aspect-ratio: 1;
  background-color: currentColor;
  width: 1.5rem;
  display: inline-block;
  mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  mask-position: center;
  mask-size: 100%;
  mask-repeat: no-repeat;
}

:where(.menu li:empty) {
  background-color: hsl(var(--bc) / .1);
  height: 1px;
  margin: .5rem 1rem;
}

.menu :where(li ul):before {
  background-color: hsl(var(--bc) / .1);
  content: "";
  width: 1px;
  position: absolute;
  top: .75rem;
  bottom: .75rem;
  left: 0;
}

.menu :where(li:not(.menu-title) > :not(ul):not(details):not(.menu-title)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  text-align: left;
  border-radius: var(--rounded-btn, .5rem);
  text-wrap: balance;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):not(summary):not(.active).focus, :where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):not(summary):not(.active):focus, :where(.menu li:not(.menu-title):not(.disabled) > :not(ul):not(details):not(.menu-title)):is(summary):not(.active):focus-visible, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active).focus, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active):focus, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):is(summary):not(.active):focus-visible {
  cursor: pointer;
  background-color: hsl(var(--bc) / .1);
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.menu li > :not(ul):not(.menu-title):not(details):active, .menu li > :not(ul):not(.menu-title):not(details).active, .menu li > details > summary:active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.menu :where(li > details > summary)::-webkit-details-marker {
  display: none;
}

.menu :where(li > details > summary):after, .menu :where(li > .menu-dropdown-toggle):after {
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  justify-self: end;
  width: .5rem;
  height: .5rem;
  margin-top: -.5rem;
  transition-property: transform, margin-top;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  transform: rotate(45deg);
  box-shadow: 2px 2px;
}

.menu :where(li > details[open] > summary):after, .menu :where(li > .menu-dropdown-toggle.menu-dropdown-show):after {
  margin-top: 0;
  transform: rotate(225deg);
}

.mockup-code:before {
  content: "";
  opacity: .3;
  border-radius: 9999px;
  width: .75rem;
  height: .75rem;
  margin-bottom: 1rem;
  display: block;
  box-shadow: 1.4em 0, 2.8em 0, 4.2em 0;
}

.mockup-code pre {
  padding-right: 1.25rem;
}

.mockup-code pre:before {
  content: "";
  margin-right: 2ch;
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .75rem;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .5rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

.modal:not(dialog:not(.modal-open)), .modal::backdrop {
  background-color: #0000004d;
  animation: .2s ease-out modal-pop;
}

.modal-open .modal-box, .modal-toggle:checked + .modal .modal-box, .modal:target .modal-box, .modal[open] .modal-box {
  --tw-translate-y: 0px;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.modal-action > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

.progress::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
}

.progress:indeterminate {
  --progress-color: hsl(var(--bc));
  background-image: repeating-linear-gradient(90deg, var(--progress-color) -1%, var(--progress-color) 10%, transparent 10%, transparent 90%);
  background-position-x: 15%;
  background-size: 200%;
  animation: 5s ease-in-out infinite progress-loading;
}

.progress::-webkit-progress-bar {
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
}

.progress::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
}

.progress:indeterminate::-moz-progress-bar {
  background-color: #0000;
  background-image: repeating-linear-gradient(90deg, var(--progress-color) -1%, var(--progress-color) 10%, transparent 10%, transparent 90%);
  background-position-x: 15%;
  background-size: 200%;
  animation: 5s ease-in-out infinite progress-loading;
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1)) inset, 0 0 0 12px hsl(var(--b1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1)) inset, 0 0 0 3px hsl(var(--b1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
  }
}

.range:focus-visible::-webkit-slider-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
}

.range:focus-visible::-moz-range-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
}

.range::-webkit-slider-runnable-track {
  background-color: hsl(var(--bc) / .1);
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
}

.range::-moz-range-track {
  background-color: hsl(var(--bc) / .1);
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
}

.range::-webkit-slider-thumb {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
  appearance: none;
  color: hsl(var(--range-shdw));
  --filler-size: 100rem;
  --filler-offset: .6rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.range::-moz-range-thumb {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
  color: hsl(var(--range-shdw));
  --filler-size: 100rem;
  --filler-offset: .5rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
  border-style: none;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 50%;
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.select-bordered {
  --tw-border-opacity: .2;
}

.select:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.select-disabled, .select:disabled, .select[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.select-disabled::placeholder, .select:disabled::placeholder, .select[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.select-multiple, .select[multiple], .select[size].select:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

[dir="rtl"] .select {
  background-position: 12px calc(1px + 50%), 16px calc(1px + 50%);
}

.steps .step:before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  content: "";
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: .5rem;
  margin-left: -100%;
  top: 0;
}

.steps .step:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: 9999px;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  place-items: center;
  width: 2rem;
  height: 2rem;
  display: grid;
  position: relative;
}

.steps .step:first-child:before {
  content: none;
}

.steps .step[data-content]:after {
  content: attr(data-content);
}

.tab.tab-active:not(.tab-disabled):not([disabled]) {
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
}

.tab:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.tab:focus-visible {
  outline-offset: -3px;
  outline: 2px solid;
}

.tab:focus-visible.tab-lifted {
  border-bottom-right-radius: var(--tab-radius, .5rem);
  border-bottom-left-radius: var(--tab-radius, .5rem);
}

.tab-disabled, .tab[disabled] {
  cursor: not-allowed;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.tabs-boxed .tab {
  border-radius: var(--rounded-btn, .5rem);
}

.table :where(th, td) {
  vertical-align: middle;
  padding: .75rem 1rem;
}

.table tr.active, .table tr.active:nth-child(2n), .table-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
}

.table-zebra tr.active, .table-zebra tr.active:nth-child(2n), .table-zebra-zebra tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
}

.table :where(thead, tbody) :where(tr:not(:last-child)), .table :where(thead, tbody) :where(tr:first-child:last-child) {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-bottom-color: hsl(var(--b2) / var(--tw-border-opacity));
}

.table :where(thead, tfoot) {
  white-space: nowrap;
  color: hsl(var(--bc) / .6);
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.textarea:focus {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.textarea-ghost {
  --tw-bg-opacity: .05;
}

.textarea-ghost:focus {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  box-shadow: none;
}

.textarea-disabled, .textarea:disabled, .textarea[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.textarea-disabled::placeholder, .textarea:disabled::placeholder, .textarea[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.toast > * {
  animation: .25s ease-out toast-pop;
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

[dir="rtl"] .toggle {
  --handleoffsetcalculator: calc(var(--handleoffset) * 1);
}

.toggle:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: hsl(var(--bc) / .2);
}

.toggle:checked, .toggle[checked="true"], .toggle[aria-checked="true"] {
  --handleoffsetcalculator: var(--handleoffset);
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}

[dir="rtl"] .toggle:checked, [dir="rtl"] .toggle[checked="true"], [dir="rtl"] .toggle[aria-checked="true"] {
  --handleoffsetcalculator: calc(var(--handleoffset) * -1);
}

.toggle:indeterminate {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

[dir="rtl"] .toggle:indeterminate {
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px var(--tglbg) inset, calc(var(--handleoffset) / -2) 0 0 2px var(--tglbg) inset, 0 0 0 2px var(--tglbg) inset;
}

.toggle:disabled {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  opacity: .3;
  --togglehandleborder: 0 0 0 3px hsl(var(--bc)) inset, var(--handleoffsetcalculator) 0 0 3px hsl(var(--bc)) inset;
  background-color: #0000;
}

.rounded-box {
  border-radius: var(--rounded-box, 1rem);
}

.btm-nav-xs > :where(.active) {
  border-top-width: 1px;
}

.btm-nav-sm > :where(.active), .btm-nav-md > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-lg > :where(.active) {
  border-top-width: 4px;
}

.btn-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
}

.btn-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
}

.btn-square:where(.btn-xs) {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-square:where(.btn-sm) {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-square:where(.btn-md) {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-square:where(.btn-lg) {
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.btn-circle:where(.btn-xs) {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-md) {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle:where(.btn-lg) {
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.join.join-vertical {
  flex-direction: column;
}

.join.join-vertical .join-item:first-child:not(:last-child), .join.join-vertical :first-child:not(:last-child) .join-item {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.join.join-vertical .join-item:last-child:not(:first-child), .join.join-vertical :last-child:not(:first-child) .join-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.join.join-horizontal {
  flex-direction: row;
}

.join.join-horizontal .join-item:first-child:not(:last-child), .join.join-horizontal :first-child:not(:last-child) .join-item {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
}

.join.join-horizontal .join-item:last-child:not(:first-child), .join.join-horizontal :last-child:not(:first-child) .join-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}

.steps-horizontal .step {
  text-align: center;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: center;
  display: grid;
}

.steps-vertical .step {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

:where(.toast) {
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  inset: auto 0 0 auto;
}

.toast:where(.toast-start) {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 0;
  right: auto;
}

.toast:where(.toast-center) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 50%;
  right: 50%;
}

.toast:where(.toast-end) {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: auto;
  right: 0;
}

.toast:where(.toast-bottom) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: auto;
  bottom: 0;
}

.toast:where(.toast-middle) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  bottom: auto;
}

.toast:where(.toast-top) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 0;
  bottom: auto;
}

.tooltip {
  --tooltip-offset: calc(100% + 1px + var(--tooltip-tail, 0px));
  display: inline-block;
  position: relative;
}

.tooltip:before {
  pointer-events: none;
  z-index: 1;
  content: var(--tw-content);
  --tw-content: attr(data-tip);
  position: absolute;
}

.tooltip:before, .tooltip-top:before {
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-offset);
  transform: translateX(-50%);
}

.tooltip-bottom:before {
  top: var(--tooltip-offset);
  bottom: auto;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.tooltip-left:before {
  top: 50%;
  left: auto;
  right: var(--tooltip-offset);
  bottom: auto;
  transform: translateY(-50%);
}

.tooltip-right:before {
  top: 50%;
  left: var(--tooltip-offset);
  bottom: auto;
  right: auto;
  transform: translateY(-50%);
}

.btn-group .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.btn-group .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.btn-group-horizontal .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-horizontal .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  margin-top: 0;
  margin-left: -1px;
}

.btn-group-horizontal .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.btn-group-vertical .btn:first-child:not(:last-child) {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.join.join-vertical > :where(:not(:first-child)) {
  margin-top: -1px;
  margin-left: 0;
  margin-right: 0;
}

.join.join-horizontal > :where(:not(:first-child)) {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1px;
}

.modal-top :where(.modal-box) {
  --tw-translate-y: -2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  width: 100%;
  max-width: none;
}

.modal-middle :where(.modal-box) {
  --tw-translate-y: 0px;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  width: 91.6667%;
  max-width: 32rem;
}

.modal-bottom :where(.modal-box) {
  --tw-translate-y: 2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  width: 100%;
  max-width: none;
}

.steps-horizontal .step {
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  min-width: 4rem;
}

.steps-horizontal .step:before {
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  width: 100%;
  height: .5rem;
  margin-left: -100%;
}

.steps-vertical .step {
  grid-template-rows: auto;
  grid-template-columns: 40px 1fr;
  justify-items: start;
  gap: .5rem;
  min-height: 4rem;
}

.steps-vertical .step:before {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: .5rem;
  height: 100%;
  margin-left: 50%;
}

[dir="rtl"] .steps-vertical .step:before {
  margin-right: auto;
}

.tooltip {
  text-align: center;
  --tooltip-tail: .1875rem;
  --tooltip-color: hsl(var(--n));
  --tooltip-text-color: hsl(var(--nc));
  --tooltip-tail-offset: calc(100% + .0625rem - var(--tooltip-tail));
  display: inline-block;
  position: relative;
}

.tooltip:before, .tooltip:after {
  opacity: 0;
  transition: color .2s cubic-bezier(.4, 0, .2, 1) .1s, background-color .2s cubic-bezier(.4, 0, .2, 1) .1s, border-color .2s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .2s cubic-bezier(.4, 0, .2, 1) .1s, fill .2s cubic-bezier(.4, 0, .2, 1) .1s, stroke .2s cubic-bezier(.4, 0, .2, 1) .1s, opacity .2s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .2s cubic-bezier(.4, 0, .2, 1) .1s, transform .2s cubic-bezier(.4, 0, .2, 1) .1s, filter .2s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s;
}

.tooltip:after {
  content: "";
  border-style: solid;
  border-width: var(--tooltip-tail, 0);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

.tooltip:before {
  background-color: var(--tooltip-color);
  color: var(--tooltip-text-color);
  border-radius: .25rem;
  width: max-content;
  max-width: 20rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.tooltip.tooltip-open:before, .tooltip.tooltip-open:after, .tooltip:hover:before, .tooltip:hover:after, .tooltip:has(:focus-visible):after, .tooltip:has(:focus-visible):before {
  opacity: 1;
  transition-delay: 75ms;
}

.tooltip:not([data-tip]):hover:before, .tooltip:not([data-tip]):hover:after {
  visibility: hidden;
  opacity: 0;
}

.tooltip:after, .tooltip-top:after {
  border-color: var(--tooltip-color) transparent transparent transparent;
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-tail-offset);
  transform: translateX(-50%);
}

.tooltip-bottom:after {
  border-color: transparent transparent var(--tooltip-color) transparent;
  top: var(--tooltip-tail-offset);
  bottom: auto;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.tooltip-left:after {
  border-color: transparent transparent transparent var(--tooltip-color);
  top: 50%;
  left: auto;
  right: calc(var(--tooltip-tail-offset)  + .0625rem);
  bottom: auto;
  transform: translateY(-50%);
}

.tooltip-right:after {
  border-color: transparent var(--tooltip-color) transparent transparent;
  top: 50%;
  left: calc(var(--tooltip-tail-offset)  + .0625rem);
  bottom: auto;
  right: auto;
  transform: translateY(-50%);
}

.input-group {
  font-size: .875rem;
  line-height: 1.25rem;
}

.inbar-btn {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.inbar-btn:where(.btn-xs) {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.inbar-btn:where(.btn-sm) {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.inbar-btn:where(.btn-md) {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.inbar-btn:where(.btn-lg) {
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.inbar-btn {
  width: 4rem;
  height: 4rem;
}

@media (width >= 1024px) {
  .inbar-btn {
    width: 6rem;
    height: 6rem;
  }
}

.input-label {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  width: 7rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  display: flex;
}

.file-input::file-selector-button {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-style: none;
  width: 7rem;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-right-1 {
  right: -.25rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-\[5rem\] {
  left: 5rem;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: .5rem;
}

.top-\[-14rem\] {
  top: -14rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.m-auto {
  margin: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-\[-\.3rem\] {
  margin-left: -.3rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-\[-\.7rem\] {
  margin-top: -.7rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-screen {
  max-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-10 {
  width: 2.5rem;
}

.w-16 {
  width: 4rem;
}

.w-24 {
  width: 6rem;
}

.w-4 {
  width: 1rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-9 {
  width: 2.25rem;
}

.w-full {
  width: 100%;
}

.min-w-full {
  min-width: 100%;
}

.\!max-w-max {
  max-width: max-content !important;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.-skew-x-12 {
  --tw-skew-x: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\!animate-none {
  animation: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-se-resize {
  cursor: se-resize;
}

.resize {
  resize: both;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.\!flex-nowrap {
  flex-wrap: nowrap !important;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-base-content {
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
}

.border-neutral {
  --tw-border-opacity: 1;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-base-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
}

.bg-base-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2) / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-contain {
  object-fit: contain;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-center {
  text-align: center;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-none {
  line-height: 1;
}

.text-base-content {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.text-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur-\[2px\] {
  --tw-blur: blur(2px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-200 {
  transition-delay: .2s;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
  border-radius: var(--scrollbar-track-radius);
}

.scrollbar-thin::-webkit-scrollbar-track:hover {
  background-color: var(--scrollbar-track-hover, var(--scrollbar-track));
}

.scrollbar-thin::-webkit-scrollbar-track:active {
  background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)));
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: var(--scrollbar-thumb-radius);
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb));
}

.scrollbar-thin::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)));
}

.scrollbar-thin::-webkit-scrollbar-corner {
  background-color: var(--scrollbar-corner);
  border-radius: var(--scrollbar-corner-radius);
}

.scrollbar-thin::-webkit-scrollbar-corner:hover {
  background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner));
}

.scrollbar-thin::-webkit-scrollbar-corner:active {
  background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)));
}

.scrollbar-thin {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial);
}

.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: block;
}

.scrollbar-thumb-base-content {
  --scrollbar-thumb: hsl(var(--bc) / 1) !important;
}

.hide {
  opacity: 0;
  transition: opacity .2s, display .2s;
  display: none;
}

.show {
  opacity: 1;
  display: block;
}

.\[--btn-focus-scale\:0\.98\] {
  --btn-focus-scale: .98;
}

@media (hover: hover) and (pointer: fine) {
  .group:hover .group-hover\:block {
    display: block;
  }

  .group:hover .group-hover\:opacity-70 {
    opacity: .7;
  }
}

.group:active .group-active\:block {
  display: block;
}

@media (hover: hover) and (pointer: fine) {
  .hover\:border-2:hover {
    border-width: 2px;
  }

  .hover\:bg-base-100:hover {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  }

  .hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  }

  .hover\:text-base-content:hover {
    --tw-text-opacity: 1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
  }

  .hover\:grayscale:hover {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
}

.focus\:border-2:focus, .active\:border-2:active {
  border-width: 2px;
}

.active\:grayscale:active {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (width >= 475px) {
  .xs\:h-32 {
    height: 8rem;
  }

  .xs\:w-auto {
    width: auto;
  }

  .xs\:min-w-0 {
    min-width: 0;
  }

  .xs\:flex-row {
    flex-direction: row;
  }

  .xs\:flex-col {
    flex-direction: column;
  }
}

@media (width >= 768px) {
  .md\:w-2\/3 {
    width: 66.6667%;
  }
}

@media (width >= 1024px) {
  .lg\:block {
    display: block;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }
}

@media (width >= 1280px) {
  .xl\:ml-\[-\.75rem\] {
    margin-left: -.75rem;
  }

  .xl\:w-4\/5 {
    width: 80%;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible;
  }
}

@media (width >= 1536px) {
  .\32 xl\:w-2\/3 {
    width: 66.6667%;
  }
}
/*# sourceMappingURL=index.bfd6ba0d.css.map */
