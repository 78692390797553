@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .hide {
        transition: opacity 200ms, display 200ms;
        display: none;
        opacity: 0;
    }

    .show {
        display: block;
        opacity: 1;
    }  
}

@layer components {
    .input-group {
        @apply text-sm;
    }

    .inbar-btn {
        @apply w-16 h-16 btn-square lg:w-24 lg:h-24;
    }

    .input-label {
        @apply bg-base-300 flex items-center justify-center uppercase w-28 px-2 font-semibold text-center text-sm;
    }

    .file-input::file-selector-button {
        @apply bg-base-300 border-none text-base-content w-28;
    }
}